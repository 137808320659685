import React, { useState } from 'react';
import Header from '../component/Header';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

function DatabaseAccess() {
    const validationSchema = Yup.object().shape({
        selectedSkills: Yup.array().min(1, 'Select at least one skill').required('Skills are required'),
    });

    const skillsOptions = [
        { value: 'option1', label: 'option1' },
        { value: 'option2', label: 'option2' },
        { value: 'option3', label: 'option3' },
        { value: 'option4', label: 'option4' },
    ];
    const handleSubmit = (values) => {
        // Handle form submission
        console.log(values.selectedSkills);
    };

    return (
        <>

            <Header />
            <main >
                <div className="mt-5">
                    <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Database Access</h1>
                    </div>
                    <section className='py-4'>
                        <div className='container'>
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <div class="mb-2 mt-lg-0 mt-3 text-end">
                                        <button type="button" class="btn btn-info text-light px-lg-5">Download Qualitative / Open Ended</button>
                                    </div>
                                </div>          
                                {/* left form start */}
                                <div className='col-lg-6'>
                                    <div className='form-theme included-label rounded-4 shadow'>
                                        <h2 className='h5 bg-light border-bottom border-primary border-4  p-3'>Access Form</h2>
                                        <Formik initialValues={{ selectedSkills: [] }} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                            <Form className='p-4'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='row gy-1'>
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Country</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="Country"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="Country"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Industry</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="Industry"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="Industry"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Employee Size</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="EmployeeSize"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="EmployeeSize"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Revenue</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="Revenue"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="Revenue"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Job Title</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="JobTitle"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="JobTitle"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Department</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="Department"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    />
                                                                    <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    />

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='row gy-1'>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary mt-3'>No. of Database</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>With Email</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>Mobile No.</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>Landline No.</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithEmail" className="form-label">Database Access with email</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithEmail' placeholder="No. of Database Access with email" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithMobile" className="form-label">Database Access with Mobile Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithMobile' placeholder="No. of Database Access with Mobile Number" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithContact" className="form-label">Database Access with Contact Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithMobile' placeholder="No. of Database Access with Contact Number" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessTo" className="form-label">Access to</label>
                                                                    <select className="form-select" name="AccessTo" required>
                                                                        <option value="">--Select--</option>
                                                                        <option>HR</option>
                                                                        <option>IT</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <button type="submit" className='w-100 my-3 py-3 btn btn-md rounded-2 btn-warning'>Access</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                                {/* left form end */}
                                <div className='col-lg-6 data-display-table'>
                                    <div className='table-responsive'>
                                        <table className="table table-theme table-striped table-primary">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="100">Date</th>
                                                    <th scope="col" >PM Name</th>
                                                    <th scope="col" width="100">No. of Database Access</th>
                                                    <th scope="col" width="80">Database Used</th>
                                                    <th scope="col">Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>13-11-2023</td>
                                                    <td>Amritash Mishra</td>
                                                    <td>250</td>
                                                    <th>250</th>
                                                    <td className='fw-bold'>
                                                        <span className='d-block text-success'>Done</span>
                                                        <span className='d-none text-danger'>Pending</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>13-11-2023</td>
                                                    <td>Sameer</td>
                                                    <td>250</td>
                                                    <th>150</th>
                                                    <td className='fw-bold'>
                                                        <span className='d-none text-success'>Done</span>
                                                        <span className='d-block text-danger'>Pending</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>13-11-2023</td>
                                                    <td>Amritash Mishra</td>
                                                    <td>250</td>
                                                    <th>250</th>
                                                    <td className='fw-bold'>
                                                        <span className='d-block text-success'>Done</span>
                                                        <span className='d-none text-danger'>Pending</span>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </main>
        </>
    );
};

export default DatabaseAccess;
