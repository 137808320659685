import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LoginView from './pages/LoginView'
import Header from './component/Header';
import SignupView from './pages/SignupView';
import CompanyDetailsInput from './pages/CompanyDetailsInput';
import DataInputPrimary from './pages/DataInputPrimary';
import DataInputSecondary from './pages/DataInputSecondary';
import DatabaseAccess from './pages/DatabaseAccess';
import ResearcherDatabaseAccess from './pages/ResearcherDatabaseAccess';
import PrimaryResearcherAccess from './pages/PrimaryResearcherAccess';

function App() {
  return (
    <>
    {/* <Header /> */}

      <Router>
        <Routes>
          <Route exact path="/" element={<LoginView />}></Route>
          <Route exact path="/signup" element={<SignupView />}></Route>
          <Route exact path="/company-details-input" element={<CompanyDetailsInput />}></Route>
          <Route exact path="/data-input-primary" element={<DataInputPrimary />}></Route>
          <Route exact path="/data-input-secondary" element={<DataInputSecondary />}></Route>
          <Route exact path="/database-access" element={<DatabaseAccess />}></Route>
          <Route exact path="/researche-database-access" element={<ResearcherDatabaseAccess />}></Route>
          <Route exact path="/primary-researcher-access" element={<PrimaryResearcherAccess />}></Route>

          {/* <Route path="*" element={<NotFound />}></Route> */}
        </Routes>
    </Router>
    </>
  );
}

export default App;
