import React, { useState } from 'react';
import Header from '../component/Header';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

function PrimaryResearcherAccess() {
    const [tags, setTags] = useState([])
    const [tagsMobile, setTagsMobile] = useState([])
    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
    }
    function handleKeyDownMobile(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        setTagsMobile([...tagsMobile, value])
        e.target.value = ''
    }
    function removeTag(index) {
        setTags(tags.filter((el, i) => i !== index))
    }
    function removeTagMobile(index) {
        setTagsMobile(tagsMobile.filter((el, i) => i !== index))
    }
    return (
        <>

            <Header />
            <main >
                <div className="mt-5">
                    <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Primary  Researcher Access</h1>
                    </div>
                 
                    <section className='py-4'>
                        <div className='container'>
                            <div className='row g-3'>
                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p>Total: <strong>1/50</strong> </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>Email: <strong className='text-danger'>Send</strong> </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>Dial: <strong className='text-warning'>Response</strong> </p>
                                    </div>
                                </div>
                                    <div className='table-responsive'>
                                        <table className="table table-theme table-striped table-primary">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="150">Data</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Company Name</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Industry</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Global Employee Size</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Revenue</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Country</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Name</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Job Title</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Department</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Email ID</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile Number</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Landline</td>
                                                    <td></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='form-theme included-label rounded-4 shadow '>
                                        <h2 className='h5 bg-light border-bottom border-warning border-4  p-3'>Primary Form</h2>
                                        <form className="p-4">
                                            <div className='row'>
                                                
                                                <div className='col-lg-12'>
                                                    <div className='mb-2 mt-lg-0 mt-3'>
                                                        <button type='button' className='btn btn-primary px-lg-5'>Add Question</button>
                                                    </div>
                                                    <div className='row gy-1'>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="AddQuestionSegment" className="form-label">Question Segment</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" name='AddQuestionSegment' placeholder="Add Question Segment" />
                                                                    <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="AddCompanyNamePlus">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12"><div class="form-group">
                                                            <label for="SelectQuestionSegment" class="form-label">Select Question Segment</label>
                                                            <select class="form-select" name="SelectQuestionSegment" required="">
                                                                <option value="">--Select--</option>
                                                                <option>HR</option>
                                                                <option>IT</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                       
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="QuestionCategory" className="form-label">Question Category</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" name='QuestionCategory' placeholder="Type Question Category" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="QuestionCategorySub" className="form-label">Question Sub Category</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" name='QuestionCategorySub' placeholder="Type Question Sub Category" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="ProjectName" className="form-label">Project Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" name='ProjectName' placeholder="Type Project Name" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12"><div class="form-group">
                                                            <label for="QuestionType" class="form-label">Select Question Type</label>
                                                            <select class="form-select" name="QuestionType" required="">
                                                                <option value="">--Select--</option>
                                                                <option>HR</option>
                                                                <option>IT</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="QuestionAuto" className="form-label">Write the Question</label>
                                                                <div class="form-floating">
                                                                    <textarea class="form-control h-80px"  placeholder="Type the Question" id="QuestionAuto"></textarea>
                                                                    <label for="QuestionAuto">Type the Question</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="Answer" className="form-label">Write the Answer</label>
                                                                <div class="form-floating">
                                                                    <textarea class="form-control h-80px"  placeholder="Type the Answer" id="Answer"></textarea>
                                                                    <label for="Answer">Type the Answer</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label for="SelectAnswer" className="form-label">Write the Answer</label>
                                                                <div class="form-floating">
                                                                    <textarea class="form-control h-80px"  placeholder="Search the Answer" id="SelectAnswer"></textarea>
                                                                    <label for="SelectAnswer">Select the Answer</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                      

                                                      

                                                        <div className='col-12'>
                                                            <button className="w-100 my-2 btn btn-md rounded-2 btn-warning" type="submit">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                {/* left form end */}

                            </div>
                        </div>
                    </section>
                   
                </div>
            </main>
        </>
    );
};

export default PrimaryResearcherAccess;
