import React, {useState} from 'react';
import Header from '../component/Header';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

function DataInputPrimary() {
    const [tags, setTags] =useState([])
    const [tagsMobile, setTagsMobile] =useState([])
    function handleKeyDown(e){
        if(e.key !=='Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        e.target.value =''
    }
    function handleKeyDownMobile(e){
        if(e.key !=='Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTagsMobile([...tagsMobile, value])
        e.target.value =''
    }
    function removeTag(index){
        setTags(tags.filter((el, i) => i!== index))
    }
    function removeTagMobile(index){
        setTagsMobile(tagsMobile.filter((el, i) => i!== index))
    }
    return (
        <>

            <Header />
            <main >
                <div className="mt-5">
                    <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>PRIMARY DATA INPUT</h1>
                    </div>
                    <section className='data-display-table pt-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='table-responsive '>
                                        <table className="table table-theme table-striped table-secondary">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Entered Variable</th>
                                                    <th scope="col">Updated Variable</th>
                                                    <th scope="col">Email Updated</th>
                                                    <th scope="col">Contact Updated</th>
                                                    <th scope="col">Collateral Uploaded</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Amritash Mishra</th>
                                                    <td>
                                                        1225 
                                                        <div><small><strong>Today:</strong> 35 </small> </div>
                                                    </td>
                                                    <td>
                                                        1226 
                                                        <div><small><strong>Today:</strong> 36 </small> </div>
                                                    </td>
                                                    <td>
                                                        1227 
                                                        <div><small><strong>Today:</strong> 37 </small> </div>
                                                    </td>
                                                    <td>
                                                        1228 
                                                        <div><small><strong>Today:</strong> 38 </small> </div>
                                                    </td>
                                                    <td>
                                                        1229 
                                                        <div><small><strong>Today:</strong> 39 </small> </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='py-4'>
                        <div className='container'>
                            <div className='row g-3'>
                                <div className='col-lg-4'>
                                    <div className='form-theme included-label rounded-4 shadow '>
                                        <h2 className='h5 bg-light border-bottom border-warning border-4  p-3'>Update Form</h2>
                                        <form className="p-4">
                                            <div className='row gy-1'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="AddCompanyName" className="form-label">Add Company Name</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='AddCompanyName' placeholder="Add Company Name" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="AddCompanyNamePlus">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="SelectCompanyName" className="form-label">Select Company Name</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='SelectCompanyName' placeholder="Select Company Name" />
                                                            <span className="input-group-text bg-transparent border-0" id="AddCompanyNamePlus">
                                                                &nbsp;&nbsp;&nbsp;
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="EmployeeSize" className="form-label">Employee Size</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='EmployeeSize' placeholder="Employee Size" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="AddCompanyNamePlus">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>

                                                        <label for="Revenue" className="form-label">Revenue</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='Revenue' placeholder="Revenue" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="AddCompanyNamePlus">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="Industry" className="form-label">Industry</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='Industry' placeholder="Industry" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="Industry">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="CompanyCountry" className="form-label">Company Country</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='CompanyCountry' placeholder="Company Country" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="AddCompanyNamePlus">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="ComapnyState" className="form-label">Comapny State</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='ComapnyState' placeholder="Company State" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="ComapnyState">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="ComapanyCity" className="form-label">Company City</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='CompanyCity' placeholder="City" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="CompanyCity">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="Website" className="form-label">Website</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name='Website' placeholder="Website Link" />
                                                            <a href='javascript:void(0)' className="input-group-text bg-warning border-0" id="AddCompanyNamePlus">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group mt-2'>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input d-none" id="UploadCollateral" />
                                                            <label className="btn-danger btn custom-file-label d-flex align-items-center w-100 justify-content-center" for="UploadCollateral">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf me-2" viewBox="0 0 16 16">
                                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                                        <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                                                    </svg>
                                                                </div>
                                                                <div>Upload Collateral</div>

                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='col-12'>
                                                    <button className="w-100 my-2 btn btn-md rounded-2 btn-warning" type="submit">Save</button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                {/* left form end */}
                                <div className='col-lg-4'>
                                    <div className='form-theme included-label rounded-4 shadow'>
                                        <h2 className='h5 bg-light border-bottom border-primary border-4  p-3'>Add Form</h2>
                                        <form className="p-4">
                                            <div className='row gy-1'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="Department" className="form-label">Department</label>
                                                        <select className="form-select" name="Department" required>
                                                            <option value="">--Select--</option>
                                                            <option>HR</option>
                                                            <option>IT</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="JobTitle" className="form-label">Job Title</label>
                                                        <select className="form-select" name="JobTitle" required>
                                                            <option value="">--Select--</option>
                                                            <option>Admin</option>
                                                            <option>IT</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="ComapanyRepresentativeName" className="form-label">Comapany Representative Name</label>
                                                        <input type="text" className="form-control" name="ComapanyRepresentativeName" placeholder="Comapany Representative Name" required />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="City" className="form-label">Person City</label>
                                                        <select className="form-select" name="City" required>
                                                            <option value="">--Select--</option>
                                                            <option>New Delhi</option>
                                                            <option>Mumbai</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="State" className="form-label">Person State</label>
                                                        <select className="form-select" name="State" required>
                                                            <option value="">--Select--</option>
                                                            <option>Delhi</option>
                                                            <option>Mumbai</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="Country" className="form-label">Person Country</label>
                                                        <select className="form-select" name="Country" required>
                                                            <option value="">--Select--</option>
                                                            <option>New Delhi</option>
                                                            <option>Mumbai</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="LinkedInProfile" className="form-label">LinkedIn Profile</label>
                                                        <input type="text" className="form-control" name="LinkedInProfile" placeholder="Linkedin Profile Link" required />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                    <label for="EmailId" className="form-label">Email Id (Max 4)</label>
                                                    <input onKeyDown={handleKeyDown} type='email' className='form-control' placeholder='Enter Mobile ID' required/>
                                                            <div className='tags-container border-2'>        
                                                                {tags.map((tag, index) => (
                                                                    <div className='tag-item' key={index} >
                                                                        <span className='text'>{tag}</span>
                                                                        <span className='close' onClick={() => removeTag(index)} >&times;</span>
                                                                    </div>
                                                                )) }
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                               
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label for="MobileNo" className="form-label">Mobile No. (Max 5)</label>
                                                            <input onKeyDown={handleKeyDownMobile} name='MobileNo' type='tel' maxLength={10} minLength={10} className='form-control' placeholder='Enter Mobile No.' required/>
                                                            <div className='tags-container border-2'>        
                                                                {tagsMobile.map((tag, index) => (
                                                                    <div className='tag-item' key={index} >
                                                                        <span className='text'>{tag}</span>
                                                                        <span className='close' onClick={() => removeTagMobile(index)} >&times;</span>
                                                                    </div>
                                                                )) }
                                                            </div>
                                                    </div>
                                                </div>
                                                
                                                {/* <div className='col-12'>
                                                    <div className='form-group'>
                                                        <MultipleValueTextInput
                                                            onItemAdded={(item, allItems) => console.log(`Item added: ${item}`)}
                                                            onItemDeleted={(item, allItems) => console.log(`Item removed: ${item}`)}
                                                            label="Email Id (Max 4)                                               "
                                                            name="EmailId                                                            "
                                                            className="form-control"
                                                            placeholder="Email ID; separate with ENTER or COMMA."
                                                        />
                                                    </div>
                                                </div> */}
                                                
                                                
                                                <div className='col-12'>
                                                    <button className="w-100 my-3 btn btn-md rounded-2 btn-primary" type="submit">Add</button>
                                                </div>
                                            </div>
                                            <hr className="my-2" />
                                        </form>
                                    </div>
                                </div>
                                {/* middle form end */}
                                <div className='col-lg-4'>
                                    <div className='bg-primary h-100'>
                                        <div className='d-flex align-items-center justify-content-center h-100 p-4'>
                                            <h2 className='h5 text-light'>Collateral display after upload</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='data-display-table py-4'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='table-responsive '>
                                        <table className="table table-theme table-striped table-primary">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#ID</th>
                                                    <th scope="col">Date Of Entry</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Industry</th>
                                                    <th scope="col">Global Employee Size</th>
                                                    <th scope="col">Revenue</th>
                                                    <th scope="col">Country</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Job Title</th>
                                                    <th scope="col">Department</th>
                                                    <th scope="col">Collateral</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="row">1</td>
                                                    <td>Date Of Entry</td>
                                                    <td>Company</td>
                                                    <td>Industry</td>
                                                    <td>Global Employee Size</td>
                                                    <td>Revenue</td>
                                                    <td>Country</td>
                                                    <td>Name</td>
                                                    <td>Job Title</td>
                                                    <td>Department</td>
                                                    <td className='fw-bold'>
                                                        <span className='d-block text-success'>Yes</span>
                                                        <span className='d-none text-danger'>No</span>
                                                    </td>
                                                    <td> <button className='btn btn-warning btn-sm'>Update</button> </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">2</td>
                                                    <td>Date Of Entry</td>
                                                    <td>Company</td>
                                                    <td>Industry</td>
                                                    <td>Global Employee Size</td>
                                                    <td>Revenue</td>
                                                    <td>Country</td>
                                                    <td>Name</td>
                                                    <td>Job Title</td>
                                                    <td>Department</td>
                                                    <td className='fw-bold'>
                                                        <span className='d-none text-success'>Yes</span>
                                                        <span className='d-block text-danger'>No</span>
                                                    </td>
                                                    <td> <button className='btn btn-warning btn-sm'>Update</button> </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">3</td>
                                                    <td>Date Of Entry</td>
                                                    <td>Company</td>
                                                    <td>Industry</td>
                                                    <td>Global Employee Size</td>
                                                    <td>Revenue</td>
                                                    <td>Country</td>
                                                    <td>Name</td>
                                                    <td>Job Title</td>
                                                    <td>Department</td>
                                                    <td className='fw-bold'>
                                                        <span className='d-block text-success'>Yes</span>
                                                        <span className='d-none text-danger'>No</span>
                                                    </td>
                                                    <td> <button className='btn btn-warning btn-sm'>Update</button> </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
};

export default DataInputPrimary;
