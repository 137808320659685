import React from 'react';


function LoginView(){

  return (
    <>
      <section className='bg-gray-theme  vh-100'>
        <div className='container'>
            <div className='row justify-content-center align-items-center vh-100' >
                <div className='col-lg-4'>
                    <div className='form-theme rounded-4 shadow'>
                        <div className='p-4 pb-0'>
                            <img src='/logo.png' className='img-fluid' width={200} />
                        </div>
                        <form className="p-4">
                            <div className="form-floating mb-2">
                                <input type="email" className="form-control rounded-3" id="floatingInput" placeholder="name@example.com" />
                                <label for="floatingInput">Email address</label>
                            </div>
                            <div className="form-floating mb-2">
                                <input type="password" className="form-control rounded-3" id="floatingPassword" placeholder="Password" />
                                <label for="floatingPassword">Password</label>
                            </div>
                           
                            <div className='row my-3'>
                                <div className='col-6'>
                                    <div className="form-check">
                                        <input className="form-check-input border-dark" type="checkbox" value="" id="RememberMe" name='RememberMe' />
                                        <label className="form-check-label" for="RememberMe"><small>Remember me</small></label>
                                    </div>
                                </div>
                                <div className='col-6 text-end'>
                                    <a href='/' className='text-danger'> <small>Forgot Password</small> </a>
                                </div>
                                
                            </div>
                            <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Sign in</button>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </section>
      
    </>
  );
};

export default LoginView;
